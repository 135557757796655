import { END_POINTS } from "../constants/url";
import { CommonBody, Subscription } from "../types/General";

import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  data: {
    totalPages: number;
    count: number;
  };
  statusCode: number;
  message: string;
};

type GetUserParams = {
  page?: number;
  query: string;
  type?: number;
};

type AddSubscriptionBody = {
  name: string;
  image: string;
  description: string;
  monthlyPrice: string;
  quarterlyPrice: string;
  yearlyPrice: string;
  features: any;
};

type PutBlockParams = {
  subsId: string;
  encryptedBody: CommonBody;
};

type UpdateParams = {
  id: string | undefined;
  encryptedBody: CommonBody;
};

export const discountApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllDiscount: builder.query<
      CommonResponseType & {
        data: any;
      },
      GetUserParams
    >({
      query: ({ page, query }) => ({
        url: END_POINTS.discount + "?page=" + page + "&search=" + query,
        method: "GET",
      }),
    }),

    addDiscount: builder.mutation<
      { message: string; statusCode: number; data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.discount,
        method: "POST",
        body,
      }),
    }),

    getUserDropDown: builder.query<
      CommonResponseType & {
        data: any;
      },
      GetUserParams
    >({
      query: ({ query, type }) => ({
        url: END_POINTS.usersDropDown + "?search=" + query + "&type=" + type,
        method: "GET",
      }),
    }),

    getDiscountById: builder.query<
      CommonResponseType & { data: any },
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.discount}/${id}`,
        method: "GET",
      }),
    }),

    deleteDiscount: builder.mutation<CommonResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `${END_POINTS.discount}/${id}`,
        method: "DELETE",
      }),
    }),

    updateDiscount: builder.mutation<
      CommonResponseType & { data: any[] },
      UpdateParams
    >({
      query: ({ encryptedBody, id }) => ({
        url: `${END_POINTS.discount}/${id}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),
  }),
});

export const {
  useLazyGetAllDiscountQuery,
  useAddDiscountMutation,
  useLazyGetUserDropDownQuery,
  useLazyGetDiscountByIdQuery,
  useDeleteDiscountMutation,
  useUpdateDiscountMutation,
} = discountApi;
