import { CategoryOutlined } from "@mui/icons-material";
import { END_POINTS } from "../constants/url";
import {
  AllUser,
  CommonBody,
  Dashboard,
  Payments,
  SubAdmin,
} from "../types/General";

import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  data: {
    totalCount: number;
    count: number;
    category: any;
    userCount: any;
  };
  statusCode: number;
  message: string;
};

type PaymentResponse = {
  data: {
    totalCount: number;
    count: number;
    payments: Payments[];
  };
  statusCode: number;
  message: string;
};

type NotificationResponse = {
  data: {
    notification: never[];
    totalCount: number;
    count: number;
    notificationCount: number;
    data: any;
  };
  statusCode: number;
  message: string;
};

type GetUserParams = {
  page: number;
  query: string;
  type: number;
  size: number;
  categoryId: string | undefined;
};

type GetPaymentParams = {
  page: number;
  query: string;
};

type AddFaqBody = {
  question: string;
  answer: string;
};

type PutBlockUserParams = {
  userId: string;
  encryptedBody: CommonBody;
};

type PutBlockSubAdminParams = {
  subAdminId: string;
  encryptedBody: CommonBody;
};

type GetSubAdminRes = {
  statusCode: number;
  message: string;
  data: {
    subAdmin: SubAdmin[];
    subAdminCount: number;
  };
};

type GetSubAdminResById = {
  statusCode: number;
  message: string;
  data: SubAdmin;
};

export const mainAPi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query<CommonResponseType, GetUserParams>({
      query: ({ page, query, type, categoryId, size }) => ({
        url:
          END_POINTS.category +
          "?search=" +
          query +
          "&page=" +
          page +
          "&size=" +
          size +
          "&type=" +
          type +
          "&categoryId=" +
          categoryId,

        method: "GET",
      }),
    }),

    changeCategoryStatus: builder.mutation<
      { message: string; statusCode: number },
      PutBlockUserParams
    >({
      query: ({ encryptedBody, userId }) => ({
        url: `${END_POINTS.category}/${userId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),

    postAddCategory: builder.mutation<
      { message: string; statusCode: number },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.category,
        method: "POST",
        body,
      }),
    }),

    getCategoryById: builder.query<
      { message: string; statusCode: number; data: any },
      { categoryId: string | undefined }
    >({
      query: ({ categoryId }) => ({
        url: `${END_POINTS.category}/${categoryId}`,
        method: "GET",
      }),
    }),

    updateCategory: builder.mutation<
      { message: string; statusCode: number },
      PutBlockUserParams
    >({
      query: ({ encryptedBody, userId }) => ({
        url: `${END_POINTS.category}/${userId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),

    deleteCategory: builder.mutation<
      CommonResponseType,
      { categoryId: string }
    >({
      query: ({ categoryId }) => ({
        url: `${END_POINTS.category}/${categoryId}`,
        method: "DELETE",
      }),
    }),

    getUserGraph: builder.query<
      CommonResponseType & {
        data: any;
      },
      { type: string }
    >({
      query: ({ type }) => ({
        url: END_POINTS.totalUserGraph + "?type=" + type,
        method: "GET",
      }),
    }),

    getDashboard: builder.query<
      CommonResponseType & {
        data: Dashboard;
      },
      {}
    >({
      query: () => ({
        url: END_POINTS.dashboard,
        method: "GET",
      }),
    }),

    getPropertiesSearchedByArea: builder.query<
      CommonResponseType & {
        data: Dashboard;
      },
      { type: string }
    >({
      query: ({ type }) => ({
        url: END_POINTS.propertiesSearchedByArea + "?type=" + type,
        method: "GET",
      }),
    }),

    getEarningGraph: builder.query<
      CommonResponseType & {
        data: any;
      },
      { type: string }
    >({
      query: ({ type }) => ({
        url: END_POINTS.totalEarning + "?type=" + type,
        method: "GET",
      }),
    }),

    getPayments: builder.query<PaymentResponse, GetPaymentParams>({
      query: ({ page, query }) => ({
        url: END_POINTS.payment + "?search=" + query + "&page=" + page,

        method: "GET",
      }),
    }),

    getPaymentById: builder.query<
      { message: string; statusCode: number; data: Payments },
      { paymentId: string | undefined }
    >({
      query: ({ paymentId }) => ({
        url: `${END_POINTS.payment}/${paymentId}`,
        method: "GET",
      }),
    }),

    getNotifications: builder.query<NotificationResponse, GetPaymentParams>({
      query: ({ page, query }) => ({
        url: END_POINTS.notification + "?search=" + query + "&page=" + page,

        method: "GET",
      }),
    }),

    postAddNotification: builder.mutation<
      { message: string; statusCode: number },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.notification,
        method: "POST",
        body,
      }),
    }),

    deleteNotification: builder.mutation<
      CommonResponseType,
      { notificationId: string }
    >({
      query: ({ notificationId }) => ({
        url: `${END_POINTS.notification}/${notificationId}`,
        method: "DELETE",
      }),
    }),

    getLabels: builder.query<
      { message: string; statusCode: number; data: any },
      {}
    >({
      query: () => ({
        url: `${END_POINTS.label}?size=100`,

        method: "GET",
      }),
    }),

    deleteLabel: builder.mutation<CommonResponseType, { labelId: string }>({
      query: ({ labelId }) => ({
        url: `${END_POINTS.label}/${labelId}`,
        method: "DELETE",
      }),
    }),

    postAddLabel: builder.mutation<
      { message: string; statusCode: number },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.label,
        method: "POST",
        body,
      }),
    }),

    getAllSubAdmins: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          subAdmin: SubAdmin[];
        };
      },
      GetPaymentParams
    >({
      query: ({ page, query }) => ({
        url: END_POINTS.subadmin + "?page=" + page + "&search=" + query,
        method: "GET",
      }),
    }),

    changeSubAdminStatus: builder.mutation<
      { message: string; statusCode: number },
      PutBlockUserParams
    >({
      query: ({ encryptedBody, userId }) => ({
        url: `${END_POINTS.subadmin}/${userId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),

    deleteSubAdmin: builder.mutation<
      CommonResponseType,
      { subAdminId: string }
    >({
      query: ({ subAdminId }) => ({
        url: `${END_POINTS.subadmin}/${subAdminId}`,
        method: "DELETE",
      }),
    }),

    getSubAdminById: builder.query<
      GetSubAdminResById,
      { subAmin_id: string | undefined }
    >({
      query: ({ subAmin_id }) => ({
        url: `${END_POINTS.subadmin}/${subAmin_id}`,
        method: "GET",
      }),
    }),

    addSubAdmin: builder.mutation<GetSubAdminRes, CommonBody>({
      query: (body) => ({
        url: END_POINTS.subadmin,
        method: "POST",
        body,
      }),
    }),

    updateSubAdminById: builder.mutation<
      GetSubAdminRes,
      PutBlockSubAdminParams
    >({
      query: ({ subAdminId, encryptedBody }) => ({
        url: `${END_POINTS.subadmin}/${subAdminId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),

    getMyNotifications: builder.query<NotificationResponse, GetPaymentParams>({
      query: ({ page, query }) => ({
        url:
          END_POINTS.getMyNotifications + "?search=" + query + "&page=" + page,

        method: "GET",
      }),
    }),

    deleteMyNotification: builder.mutation<
      CommonResponseType,
      { notificationId: string }
    >({
      query: ({ notificationId }) => ({
        url: `${END_POINTS.clearMyNotification}/${notificationId}`,
        method: "DELETE",
      }),
    }),

    getAllFaqs: builder.query<
      CommonResponseType & {
        data: {
          data: any;
        };
      },
      {}
    >({
      query: () => ({
        url: END_POINTS.faqs,
        method: "GET",
      }),
    }),

    addFaqs: builder.mutation<
      { message: string; statusCode: number; data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.faqs,
        method: "POST",
        body,
      }),
    }),

    deleteFaqs: builder.mutation<CommonResponseType, { faqId: string }>({
      query: ({ faqId }) => ({
        url: `${END_POINTS.faqs}/${faqId}`,
        method: "DELETE",
      }),
    }),
    getDashboardCsv: builder.query<
      { statusCode: number; message: string; data: any },
      {}
    >({
      query: ({}) => ({
        url: `${END_POINTS.dashboardExport}`,
        method: "GET",
      }),
    }),

    getPaymentCsv: builder.query<any, { type: string; query: string }>({
      query: ({ type, query }) => ({
        url:
          END_POINTS.getPaymentsExportCsv +
          "?type=" +
          type +
          "&search=" +
          query,
        method: "GET",
      }),
    }),

    getRefundRequests: builder.query<
      CommonResponseType & {
        data: any;
      },
      GetPaymentParams
    >({
      query: ({ query, page }) => ({
        url: END_POINTS.refundRequest + "?search=" + query + "&page=" + page,
        method: "GET",
      }),
    }),

    getAllLinks: builder.query<
      CommonResponseType & {
        data: {
          data: any;
        };
      },
      {}
    >({
      query: () => ({
        url: END_POINTS.realEstateLink,
        method: "GET",
      }),
    }),

    deleteLinks: builder.mutation<CommonResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `${END_POINTS.realEstateLink}/${id}`,
        method: "DELETE",
      }),
    }),

    addLinks: builder.mutation<
      { message: string; statusCode: number; data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.realEstateLink,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetCategoriesQuery,
  useChangeCategoryStatusMutation,
  usePostAddCategoryMutation,
  useLazyGetCategoryByIdQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useLazyGetUserGraphQuery,
  useLazyGetDashboardQuery,
  useLazyGetEarningGraphQuery,
  useLazyGetPaymentsQuery,
  useLazyGetPaymentByIdQuery,
  useLazyGetNotificationsQuery,
  usePostAddNotificationMutation,
  useDeleteNotificationMutation,
  usePostAddLabelMutation,
  useLazyGetAllSubAdminsQuery,
  useChangeSubAdminStatusMutation,
  useDeleteSubAdminMutation,
  useAddSubAdminMutation,
  useUpdateSubAdminByIdMutation,
  useLazyGetSubAdminByIdQuery,
  useLazyGetMyNotificationsQuery,
  useDeleteMyNotificationMutation,
  useLazyGetLabelsQuery,
  useDeleteLabelMutation,
  useAddFaqsMutation,
  useLazyGetAllFaqsQuery,
  useDeleteFaqsMutation,
  useLazyGetDashboardCsvQuery,
  useLazyGetPaymentCsvQuery,
  useLazyGetPropertiesSearchedByAreaQuery,
  useLazyGetRefundRequestsQuery,
  useLazyGetAllLinksQuery,
  useAddLinksMutation,
  useDeleteLinksMutation,
} = mainAPi;
