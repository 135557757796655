// export const API_URL =
//   "https://apirealestate.appgrowthcompany.com//api/v1/admin/"; //development

export const API_URL = "https://api.maskanq8ti.net/api/v1/admin/"; //release

export const END_POINTS = {
  //onboarding
  login: "login",
  getUser: "profile",
  forgetPassword: "forgotNewPassword",
  verifyOtp: "verifySendOTP",
  updateProfile: "updateProfile",
  resetPassword: "setPassword",
  changePassword: "changepassword",
  upload: "upload",

  //user
  updateUser: "updateUser",
  user: "getUsersList",
  getUsers: "getUser",
  exportUser: "exportUserList",
  deleteUser: "deleteUser",

  // dashboard
  dashboard: "dashboard",
  totalUserGraph: "totalUser",
  totalEarning: "totalEarning",
  propertiesSearchedByArea: "propertiesSearchedByArea",
  dashboardExport: "dashboardExport",

  //main
  property: "property",
  cms: "cms",
  area: "area",
  getAreaById: "getAreaById",
  category: "category",
  membership: "membership",
  payment: "payments",
  notification: "notification",
  label: "label",
  subadmin: "subadmin",
  getMyNotifications: "getMyNotifications",
  clearMyNotification: "clearMyNotification",
  faqs: "faq",
  tutorial: "tutorial",
  getPropertyExportCsv: "getPropertyExportCsv",
  getPaymentsExportCsv: "getPaymentsExportCsv",
  getAgentRequests: "getAgentRequests",
  refundRequest: "refundRequest",
  realEstateLink: "realEstateLink",

  //discount
  discount: "discount",
  usersDropDown: "usersDropDown",
};
