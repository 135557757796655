import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";
import {
  useLazyGetPaymentCsvQuery,
  useLazyGetPaymentsQuery,
} from "../../services/main";
import { Loader, showError } from "../../constants";
import { Payments } from "../../types/General";
import Pagination from "../../components/Pagination";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";

const ManagePayments = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const [getPaymentQuery, { isLoading }] = useLazyGetPaymentsQuery();
  const [paymentCsv] = useLazyGetPaymentCsvQuery();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState(1);
  const [allPayments, setAllPayments] = useState<Payments[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const translate = useTranslation();
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });
  const [loading, setLoading] = useState(false);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);

  const getUserCsv = async () => {
    try {
      const response = await paymentCsv({
        type: "user",
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        window?.open(response?.data);
      } else {
        console.log("error");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getPayment = async () => {
    setLoading(true);
    try {
      const response = await getPaymentQuery({
        query: debouncedSearchTerm.trim(),
        page: page,
      }).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setAllPayments(response?.data?.payments || []);
        setTotalCount(response?.data?.count);
      } else {
        setAllPayments([]);
      }
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getPayment();
  }, [debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate?.main?.ManagePayments}</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button className="btn btn_primary" onClick={getUserCsv}>
                <FileDownloadIcon /> {translate?.dashboard?.ExportCsv}
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{translate?.main?.SNo}</TableCell>

                  <TableCell>{translate?.main?.Name}</TableCell>
                  <TableCell>{translate?.main?.Email}</TableCell>

                  <TableCell>{translate?.main?.PaymentMode}</TableCell>
                  <TableCell>{translate?.main?.PaymentType}</TableCell>
                  <TableCell>{translate?.main?.Amount}</TableCell>
                  <TableCell>{translate?.main?.Action}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allPayments?.length > 0
                  ? allPayments?.map((row, i) => (
                      <TableRow key={row?._id}>
                        <TableCell align="center">
                          {(page - 1) * 10 + i + 1}
                        </TableCell>

                        <TableCell>{row?.user?.fullName || "-"}</TableCell>
                        <TableCell>{row?.user?.email || "-"}</TableCell>

                        <TableCell>{row?.paymentMode || "-"}</TableCell>
                        <TableCell>
                          {row?.paymentType === 1
                            ? "Credits"
                            : row?.paymentType === 2
                            ? "Subscription"
                            : "Agent Request"}
                        </TableCell>
                        <TableCell>{row?.amount || "-"}</TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(`/manage-payments/details/${row?._id}`)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                            {/* <IconButton
                        //   onClick={() => navigate("/manageusers/usersform")}
                        >
                          <ModeEditIcon />
                        </IconButton>
                        <IconButton>
                          <DeleteIcon />
                        </IconButton> */}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  : ""}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        {allPayments?.length > 0 ? (
          <Pagination
            setPage={setPage}
            module={allPayments}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        ) : (
          ""
        )}
      </div>
    </MainContainer>
  );
};

export default ManagePayments;
